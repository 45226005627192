<template>
  <v-text-field
    v-model="data"
    dense
    :maxlength="100"
    counter
    :rules="rules"
    label=""
    solo
    flat
  ></v-text-field>
</template>

<script>
import itemMixins from './item'
export default {
    mixins: [itemMixins],
}
</script>